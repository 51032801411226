$(document).ready(function() {
   
    $(function(){
		$('#menu').slicknav();
	});

    $('.popup').fancybox();
	$('a.gallery').fancybox({
        padding : 0
    });
	$('a.galleria').fancybox();
	$("a.popupper").fancybox({
		'titleShow'			: false,
		'width'				: 500,
		'height'			: 550,
		'autoScale'			: false,
		fitToView   		: false,
		autoSize    		: false,
		'transitionIn'		: 'none',
		'transitionOut'		: 'none',
		'type'				: 'iframe'
	});


	$('.brandsMenu').click(function(e){
		e.preventDefault();
		$('.brandmenu').slideToggle();
	});

	$(".slider").bxSlider({
		auto: true,
		mode: 'fade'
	});


	$(".sliderBrand").bxSlider({
		auto: true,
		minSlides: 3,
		maxSlides: 3,
		slideWidth: 360,
		slideMargin: 10
	});

	$(".sliderSimili").bxSlider({
		auto: true,
		minSlides: 3,
		maxSlides: 3,
		slideWidth: 360,
		slideMargin: 10
	});

	$(".sliderProdotto").bxSlider({
		pagerCustom: '.productsGallerySml'
	})

	$('.datiDiversi a').click(function(){
		$('.nascosto').slideToggle();
	});


	 $('a[href^="#"]').on('click', function (e) {
        e.preventDefault();
        $(document).off("scroll");
        
      
        var target = this.hash,
            menu = target;
        $target = $(target);
        $('html, body').stop().animate({
            'scrollTop': $target.offset().top+2
        }, 500, 'swing', function () {
            window.location.hash = target;
            $(document).on("scroll", onScroll);
        });
    });

	$('.open').click(function(){
		elemento = $(this).data('elemento');
		console.log(elemento);
		if($('.area-open.'+elemento).is(":hidden") ) {
			$('.area-open').slideUp('fast');
			$('.area-open.'+elemento).slideToggle('fast');
		} else {
			$('.area-open.'+elemento).slideToggle('fast');
		}
	});

	$('#PhotoBig').css('height', window.innerHeight + 'px');

});

/*$(window).scroll(function() {
  var scrolledY = $(window).scrollTop();
  $('.PhotoBig__internal').css('background-position', 'left ' + ((scrolledY)) + 'px');
});*/
 
function ajaxPost(url,target,idform,params,callback){

	$.ajax({
	  url: url + "?" + params,
	  context: target,
	  type: 'POST',
	  data: $(idform).serialize(),
	  beforeSend: function() {
			$(target).fadeTo('fast', 0, function() {
				  // Animation complete.
				});
	  },
	  success: function(html){

		$(target).html(html);
				$(target).fadeTo('slow', 1.0, function() {
				  // Animation complete.					  
				});
			if (callback)
			{
				eval(callback);
			}
		}
	});
}


function ajaxGet(url,target,params,callback){

	$.ajax({
	  // url: url + "?" + params,
	  context: target,
	  type: 'GET',
	  beforeSend: function() {
			$(target).fadeTo('fast', 0, function() {
				  // Animation complete.
				});
	  },
	  success: function(html){

		$(target).html(html);
				$(target).fadeTo('slow', 1.0, function() {
				  // Animation complete.					  
				});
			if (callback)
			{
				eval(callback);
			}
		}
	});
}
